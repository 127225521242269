<template>
  <div>
    <v-data-table :headers="headers" :items="skus" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>SKUs</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <button
            type="button"
            class="btn btn-info px-4 py-2 ls1 mb-2 mr-1"
            @click="() => showEditSkuModal(null)"
            :disabled="isEditable"
          >
            New Item
          </button>
        </v-toolbar>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="() => showEditSkuModal(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="() => removeSkus(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
    <EditSku
      ref="editSku"
      :actionFunction="editSkus"
      :skuItem="skuItem"
      :clientId="clientId"
    ></EditSku>
  </div>
</template>

<script>
import EditSku from "@/own/components/fulfillment/products/skuContainer/EditSku";
import Swal from "sweetalert2";
export default {
  name: "SkuDatatable",
  props: ["setSku", "skus", "skuItem", "clientId", "customer", "isEditable"],
  components: { EditSku },
  data: () => ({}),
  computed: {
    headers() {
      let headers = [
        { text: "SKU ID", value: "sku_id" },
        { text: "System Code", value: "system_code" },

        { text: "Actions", value: "actions", sortable: false },
      ];
      return headers;
    },
  },
  methods: {
    showImportModal() {
      this.$refs.importData.toggleModal();
    },
    setImportDataToDataTable(importedData) {
      this.setSku([]);
      this.setSku(importedData);
    },
    showEditSkuModal(item) {
      if (this.isEditable) {
        return;
      }
      if (!this.customer) {
        Swal.fire({
          title: "",
          text: "Please, select customer for adding SKUs!",
          icon: "warning",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      }
      this.$refs.editSku.toggleModal(item);
    },

    editSkus(item, sku_id) {
      // console.log("func", item, sku_id, batch);
      let new_skus = [...this.skus];
      if (sku_id) {
        const index = new_skus.findIndex((item) => item.sku_id == sku_id);
        new_skus.splice(index, 1);
        let copy = new_skus.findIndex((sku) => sku.sku_id == item.sku_id);
        // console.log("edit", copy, new_skus);
        if (copy > -1) {
          new_skus[copy] = item;
        } else {
          new_skus.push(item);
        }
        this.setSku(new_skus);
      } else {
        let copy = new_skus.findIndex((sku) => sku.sku_id == item.sku_id);
        // console.log("add", copy, new_skus);
        if (copy > -1) {
          new_skus[copy] = item;
        } else {
          new_skus.push(item);
        }
        this.setSku(new_skus);
      }
    },
    removeSkus(item) {
      if (this.isEditable) {
        return;
      }
      let new_skus = [...this.skus];
      const index = new_skus.findIndex((sku) => sku.sku_id === item.sku_id);

      new_skus.splice(index, 1);
      this.setSku(new_skus);
    },
  },
};
</script>
